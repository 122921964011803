import React, { useEffect, useState } from "react"
import Back from "../../common/Back"
import Heading from "../../common/Heading"
import RecentCard from "../../home/recent/RecentCard"
import img from "../../images/about.jpg"
import "../../home/recent/recent.css"
import RecentCard2 from "../../home/recent2/RecentCard2"
import axios from "axios"

const Komputerler = () => {
  const [list, setItems] = useState([]);
  const [isLoading, setIsLoading] = useState(true); 

  useEffect(() => {
    getProducts();
  }, []);

  const getProducts = async () => {
    try {
      const response = await axios.get(
        "https://www.posstore.az/posstore/dashboard.php/", {
          withCredentials: false
      }
      );
      setItems(response.data.filter(item => item.category === "Komputerlər"));
    } catch (error) {
      console.error("Error fetching users:", error);
    } finally {
      setIsLoading(false);
    }
  };
  return (
    <>
       <section className='blog-out mb'>
        <Back name='Komputerlər' title='Avadanlıqlar-Komputerlər' cover={img} />
        <div className='container recent'>
        {isLoading ? (
           <div style={{display:'flex', justifyContent: 'center', alignItems:'center', height:'50vh'}} className='container'>
           <img style={{width:'50px', height:'50px', margin: '0 auto'}} src='./loading.gif'></img>
         </div>
          ) : (
        <div className='content grid3 mtop'>
        {list.map((val, index) => {
          const {id, image,category, name } = val
          return (
            <div className='box shadow' key={index}>
              <div className='img'>
                <img src={`data:image/jpeg;base64,${image}`} alt='' />
              </div>
              <div className='text'>
                <div className='category flex'>
                  <span style={{ background: category === "For Sale" ? "#25b5791a" : "#ff98001a", color: category === "For Sale" ? "#25b579" : "#ff9800" }}>{category}</span>
                  <i className='fa fa-heart'></i>
                </div>
                <h4>{name}</h4>
                <p style={{marginBottom:"10px"}}>
                          <i className='fa fa-location-dot'></i> Bakı, Azərbaycan
                        </p>
                        <a href={`/product-detail/${id}`}><button className='details-button'>Ətraflı</button></a>
              </div>
            </div>
          )
        })}
      </div>
       )}
        </div>
      </section>
    </>
  )
}

export default Komputerler
