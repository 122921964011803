import React from "react"
import Back from "../../common/Back"
import Heading from "../../common/Heading"
import img from "../../images/about.jpg"
import "./avto.css"

const Avto = () => {
  return (
    <>
      <section className='market'>
        <Back name='Avto' title='Sektorlar - Avto Ehtiyyat Hissələri' cover={img} />
        <div className='container flex mtop'>
          <div className='left row'>
            <Heading title='Avto Ehtiyyat Hissələri' subtitle='Haqqımızda daha çox oxuyun' />

            <p>"POS GROUP" şirkəti olaraq sizi salamlayırıq.

2020-ci il tarixindən rəsmi "POS GROUP"MMC şirkəti adından fəaliyyət göstərən şirkət,daima keyfiyyətli avadanlıq,yüksək xidmət və sərfəli qiymətlərlə müştərilərin etimadını qazanmışdır.

Şirkətimiz istənilən ticarət obyektləri üçün Barkod Pos sistemi avadanlıqlarının satışını və Proqram təminatının yazılımını icra edir.</p>
            <button className='btn2'>Haqqımızda daha çox</button>
          </div>
          <div className='right row'>
            <img src='./about.webp' alt='' />
          </div>
        </div>
      </section>
    </>
  )
}

export default Avto
