import React from "react"
import Back from "../common/Back"
import PriceCard from "../home/price/PriceCard"
import img from "../images/about.jpg"
import "../home/price/price.css"

const Partners = () => {
  return (
    <>
      <section className='pricing mb'>
        <Back name='Tərəfdaşlarımız' title='Bütün sektorlara aid' cover={img} />
        <div className='price container'>
          <PriceCard />
        </div>
      </section>
    </>
  )
}

export default Partners
