import React, { useEffect, useState } from "react";
import axios from "axios";
import { useNavigate, useParams } from "react-router-dom";
import Back from "../common/Back";
import img from "../images/about.jpg";
import Modal from "./Modal"; // Import the Modal component
import { toast } from "react-toastify";
import swal from 'sweetalert';

export default function ProductDetail() {
  const navigate = useNavigate();
  const [isModalOpen, setIsModalOpen] = useState(false); // State for modal visibility

  const [clientName, setclientName] = useState("");
  const [clientEmail, setclientEmail] = useState("");
  const [clientPhone, setclientPhone] = useState("");
  const [clientAddress, setclientAddress] = useState("");

  const params = useParams();
  const params2 = Number(params.id);

  const [list, setItems] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    getProducts();
  }, [params2]);

  const getProducts = async () => {
    try {
      const response = await fetch("https://www.posstore.az/posstore/dashboard.php/", {
        credentials: "omit",
      });

      if (!response.ok) {
        throw new Error("Network response was not ok");
      }

      const data = await response.json();
      setItems(data.filter((item) => Number(item.id) === params2)); // `item.id`'yi sayıya çeviriyoruz
    } catch (error) {
      console.error("Error fetching products:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleSubmitOrder = async () => {
    let inputs = {
      name: clientName,
      email: clientEmail,
      phone: clientPhone,
      address: clientAddress,
      products: list[0].name,
      totalAmt: list[0].price,
    };
    try {
      axios.post("https://www.posstore.az/posstore/orders.php/", inputs).then(function (response) {
        navigate("/");
        swal("Sifarişiniz tamamlandı");
        setclientName("");
        setclientEmail("");
        setclientPhone("");
        setclientAddress("");
      });
      setIsModalOpen(false);
    } catch (error) {
      console.error("Sifariş gönderilirken bir hata oluştu:", error);
    }
  };

  return ( 
    <>
      <Back name="Məhsul haqqında" title="Avadanlıqlar-Məhsul haqqında" cover={img} />
      <div className="container" style={{ margin: "0 auto", padding: "3rem 0" }}>
        <div className="flex-center" style={{ display: "flex", justifyContent: "space-between" }}>
        
            {isLoading ? (
              <div style={{ height:'200px', display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%' }}>
  <img style={{ width: '50px', height: '50px' }} src="/./loading.gif" alt="Loading" />
</div>

            ) : list.length > 0 ? (
              list.map((val, index) => {
                const { id, image, name, category,price, processor, generation, ram, ssd, touchscreen, size } = val;
                return (
                    <>
                    <div className="product-image" style={{ marginBottom: "2rem" }}>
                      <img
                        src={`data:image/jpeg;base64,${image}`}
                        alt={name}
                        style={{ width: "80%", objectFit: "cover", borderRadius: "10px" }}
                      />
                    </div>
                    <div className="product-info">
                      <div
                        className="category"
                        style={{
                          marginBottom: "1rem",
                          display: "flex",
                          justifyContent: "space-between",
                          fontSize: "1rem",
                          fontWeight: "600",
                          color: "gray",
                        }}
                      >
                        <span>{category}</span>
                        <i className="fa fa-heart" style={{ color: "red" }}></i>
                      </div>
                      <h4 style={{ fontSize: "1.5rem", fontWeight: "700", marginBottom: "1rem" }}>{name}</h4>
                      <h4 style={{ fontSize: "1.5rem", fontWeight: "700", marginBottom: "1rem" }}>{processor}</h4>
                      <h4 style={{ fontSize: "1.5rem", fontWeight: "700", marginBottom: "1rem" }}>{generation}</h4>
                      <h4 style={{ fontSize: "1.5rem", fontWeight: "700", marginBottom: "1rem" }}>{ram}</h4>
                      <h4 style={{ fontSize: "1.5rem", fontWeight: "700", marginBottom: "1rem" }}>{ssd}</h4>
                      <h4 style={{ fontSize: "1.5rem", fontWeight: "700", marginBottom: "1rem" }}>{size}</h4>
                      <h4 style={{ fontSize: "1.5rem", fontWeight: "700", marginBottom: "1rem" }}>{touchscreen}</h4>
                      <h4 style={{ fontSize: "1.5rem", fontWeight: "700", marginBottom: "1rem" }}>{price} AZN</h4>
                      <button
                        onClick={() => setIsModalOpen(true)}
                        style={{
                          marginTop: "1rem",
                          padding: "0.5rem 1rem",
                          backgroundColor: "#F1592A",
                          color: "white",
                          borderRadius: "20px",
                          width: "100%",
                          maxWidth: "340px",
                          cursor: "pointer",
                        }}
                      >
                        Sifariş ver
                      </button>
                    </div></>
                );
              })
            ) : (
              <p>No data found</p>
            )}
        </div>
      </div>
      <Modal isOpen={isModalOpen} onClose={() => setIsModalOpen(false)}>
          <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center", marginBottom: "1rem" }}>
            <h2 style={{ fontSize: "1.25rem", fontWeight: "600" }}>Sizin məlumatlarınız</h2>
            <button onClick={() => setIsModalOpen(false)} style={{ color: "white", cursor: "pointer" }}>
              &#10005;
            </button>
          </div>
          <form onSubmit={handleSubmitOrder}>
            <div style={{ marginBottom: "1rem" }}>
              <label style={{ display: "block", marginBottom: "0.5rem", color: "#4a5568" }}>Ad, soyad*</label>
              <input
                type="text"
                style={{ width: "100%", padding: "0.75rem", borderRadius: "10px", border: "1px solid #e2e8f0", backgroundColor: "#edf2f7" }}
                placeholder="Adınızı daxil edin"
                onChange={(event) => setclientName(event.target.value)}
                value={clientName}
                required
              />
            </div>
            <div style={{ marginBottom: "1rem" }}>
              <label style={{ display: "block", marginBottom: "0.5rem", color: "#4a5568" }}>E-mail*</label>
              <input
                type="email"
                style={{ width: "100%", padding: "0.75rem", borderRadius: "10px", border: "1px solid #e2e8f0", backgroundColor: "#edf2f7" }}
                placeholder="Emailinizi daxil edin"
                onChange={(event) => setclientEmail(event.target.value)}
                value={clientEmail}
                required
              />
            </div>
            <div style={{ marginBottom: "1rem" }}>
              <label style={{ display: "block", marginBottom: "0.5rem", color: "#4a5568" }}>Əlaqə nömrəsi*</label>
              <input
                type="number"
                style={{ width: "100%", padding: "0.75rem", borderRadius: "10px", border: "1px solid #e2e8f0", backgroundColor: "#edf2f7" }}
                placeholder="Telefon nömrənizi daxil edin"
                onChange={(event) => setclientPhone(event.target.value)}
                value={clientPhone}
                required
              />
            </div>
            <div style={{ marginBottom: "1rem" }}>
              <label style={{ display: "block", marginBottom: "0.5rem", color: "#4a5568" }}>Ünvan*</label>
              <input
                type="text"
                style={{ width: "100%", padding: "0.75rem", borderRadius: "10px", border: "1px solid #e2e8f0", backgroundColor: "#edf2f7" }}
                placeholder="Ünvanınızı daxil edin"
                onChange={(event) => setclientAddress(event.target.value)}
                value={clientAddress}
                required
              />
            </div>
            <div>
              <button
                type="submit"
                style={{
                  padding: "0.75rem 1.5rem",
                  backgroundColor: "#F1592A",
                  color: "white",
                  borderRadius: "10px",
                  width: "100%",
                  cursor: "pointer",
                }}
              >
                Sifariş ver
              </button>
            </div>
          </form>
      </Modal>
    </>
  );
}


