import React from "react";
import Heading from "../../common/Heading";
import { location } from "../../data/Data";
import "./style.css";

const Location = () => {
  return (
    <>
      <section className='location padding'>
        <div className='container'>
          <Heading title='Üstünlüklərimiz' subtitle='Biz dünyanın aparıcı aparat və proqram təminatı istehsalçıları ilə işləyirik və yüksək keyfiyyətli xidmətlərə zəmanət veririk.' />
          <div className='content grid3 mtop'>
            {location.map((item, index) => (
              <div className='box' key={index}>
                <div className='image-overlay-container'>
                  <img className='image' src={item.cover} alt='' />
                  <div className='overlay'>
                    <h5>{item.name}</h5>
                    {/* <p>
                      <label>{item.Villas}</label>
                      <label>{item.Offices}</label>
                      <label>{item.Apartments}</label>
                    </p> */}
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </section>
    </>
  );
};

export default Location;
