import axios from "axios";
import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";

export default function UpdateDevice() {
  const { id } = useParams(); // URL'den ürün ID'sini al
  const navigate = useNavigate();
  const [device, setDevice] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    axios
      .get(`https://www.posstore.az/posstore/dashboard.php/${id}`)
      .then((response) => {
        setDevice(response.data);
        setLoading(false);
      })
      .catch((error) => {
        console.error("Error fetching device:", error);
        setLoading(false);
      });
  }, [id]);

  const handleUpdate = (e) => {
    e.preventDefault();
    axios
      .put(`https://www.posstore.az/posstore/dashboard.php/${id}`, device)
      .then((response) => {
        if (response.data.status === 1) {
          alert("Cihaz başarıyla güncellendi!");
          navigate("/dashboard/devices");
        } else {
          alert("Güncelleme başarısız oldu.");
        }
      })
      .catch((error) => {
        console.error("Error updating device:", error);
      });
  };

  const handleChange = (e) => {
    setDevice({ ...device, [e.target.name]: e.target.value });
  };

  return (
    <div className="container">
      <h1>Cihaz güncəllənmə</h1>
      {loading ? (
        <img style={{ width: "50px", height: "50px" }} src="/./loading.gif" alt="loading" />
      ) : (
        <form onSubmit={handleUpdate}>
          <label>
            Ad:
            <input type="text" name="name" value={device.name} onChange={handleChange} />
          </label>
          <label>
            Prosessor:
            <input type="text" name="processor" value={device.processor} onChange={handleChange} />
          </label>
          <label>
            Qiymət:
            <input type="number" name="price" value={device.price} onChange={handleChange} />
          </label>
          <label>
            Nəsil:
            <input type="text" name="generation" value={device.generation} onChange={handleChange} />
          </label>
          <label>
            RAM:
            <input type="text" name="ram" value={device.ram} onChange={handleChange} />
          </label>
          <label>
            SSD:
            <input type="text" name="ssd" value={device.ssd} onChange={handleChange} />
          </label>
          <label>
            Ölçü:
            <input type="text" name="size" value={device.size} onChange={handleChange} />
          </label>
          <label>
            Touchscreen:
            <input type="text" name="touchscreen" value={device.touchscreen} onChange={handleChange} />
          </label>
          <button type="submit">Yenilə</button>
        </form>
      )}
    </div>
  );
}

