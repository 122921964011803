import { useEffect } from "react";
import "./App.css"
import Pages from "./components/pages/Pages"
import { toast } from "react-toastify";



function App() {

  useEffect(() => {
    const script = document.createElement("script");
    script.async = true;
    script.src = 'https://embed.tawk.to/664479d69a809f19fb3116e7/1httnnsa4';
    script.charset = 'UTF-8';
    script.setAttribute('crossorigin', '*');
    document.head.appendChild(script);

    return () => {
      document.head.removeChild(script);
    };
  }, []);



    useEffect(() => {
    const script = document.createElement("script");
    script.async = true;
    script.src = 'https://embed.tawk.to/664479d69a809f19fb3116e7/1httnnsa4';
    script.charset = 'UTF-8';
    script.setAttribute('crossorigin', '*');
    document.head.appendChild(script);

    return () => {
      document.head.removeChild(script);
    };
  }, []);

  return <Pages />
}
 
export default App
