import React from "react"
import { footer } from "../../data/Data"
import "./footer.css"

const Footer = () => {
  return (
    <>
      <section className='footerContact'>
        <div className='container'>
          <div className='send flex'>
            <div className='text'>
              <h1>Suallarınız var ?</h1>
              <p>Biznesinizə kömək edək</p>
            </div>
            <a href="/contact"><button className='btn5'>İndi əlaqə saxlayın</button></a>
          </div>
        </div>
      </section>

      <footer>
        <div className='container'>
          <div className='box'>
            <div className='logo'>
              <img src='../images/banner8.png' alt='' />
              <h2>Köməyə ehtiyacınız var?</h2>
              <p>Nərimanov Rayonu,Əliyar Əliyev Küşəsi 52, Baku, Azerbaijan 1003</p>

              <div className='input flex'>
                <input type='text' placeholder='Email' />
               <a href="/contact"> <button>Abunə ol</button></a>
              </div>
            </div>
          </div>

          {/* {footer.map((val) => (
            <div className='box'>
              <h3>{val.title}</h3>
              <ul>
                {val.text.map((items) => (
                  <li> {items.list} </li>
                ))}
              </ul>
            </div>
          ))} */}
            <div className='box'>
              <h3>Sürətli keçidlər</h3>
              <ul>
              <a href="/"><li> Ana səhifə </li></a>
              <a href="/about"><li> Haqqımızda </li></a>
              <a href="/partners"><li> Tərəfdaşlarımız </li></a>
              <a href="/avadanliqlar"><li> Avadanlıqlar </li></a>
              <a href="/contact"><li> Əlaqə </li></a>
              <a href="/sektorlar"><li> Sektorlar </li></a>
              </ul>
            </div>
            <div className='box'>
              <h3>Xidmətlərimiz</h3>
              <ul>
              <a href="/market"><li> Market </li></a>
              <a href="/restoran"><li> Restoran </li></a>
              <a href="/kafe"><li> Kafe </li></a>
              <a href="/kofeshop"><li> KofeShop </li></a>
              <a href="/xirdavat"><li> 1001 Xırdavat </li></a>
              <a href="/telefon"> <li> Telefon mağazaları </li></a>
              </ul>
            </div>
            <div className='box'>
            <h3>Sektorlar</h3>
              <ul>
              <a href="/geyim"><li> Geyim mağazaları </li></a>
              <a href="/aptek"><li> Aptek </li></a>
              <a href="kiosk"><li> Kiosk </li></a>
              <a href="/oyuncaq"><li> Oyuncaq mağazaları </li></a>
              <a href="/avto"><li> Avto Ehtiyyat</li></a>
              <a href="/zoo"><li> Zoo mağazalar </li></a>
              </ul>
            </div>
        </div>
      </footer>
      <div className='legal'>
        <span>© 2024 POS Store. Designed By BirSayt.</span>
      </div>
    </>
  )
}

export default Footer
