import React, { useEffect } from "react"
import img from "../images/about.jpg"
import Back from "../common/Back"
import "./contact.css"
import { toast } from "react-toastify"

const Contact = () => {


  return (
    <>
      <section className='contact mb'>
        <Back name='Bizimlə Əlaqə' title='Təklif və iradlar' cover={img} />
        <div className='container'>
          <form className='shadow'>
            <h4>Formu doldurun</h4> <br />
            <div>
              <input type='text' placeholder='Ad' />
              <input type='text' placeholder='Email' />
            </div> 
            <input type='text' placeholder='Mövzu' />
            <textarea placeholder="Qeydlər" cols='30' rows='10'></textarea>
            <button>Göndər</button>
          </form>
        </div>
      </section>
    </>
  )
}

export default Contact
