import React, { useEffect, useRef, useState } from "react";
import "./header.css";
import { nav } from "../../data/Data";
import { Link } from "react-router-dom";
import { FiChevronDown, FiChevronUp } from "react-icons/fi";
import { LuMessageSquare } from "react-icons/lu";
import { IoCall } from "react-icons/io5";
import { FaWhatsapp } from "react-icons/fa";


const Header = () => {

  const [navList, setNavList] = useState(false);

  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [dropdownOpen2, setDropdownOpen2] = useState(false);
  const dropdownRef = useRef(null);
  const dropdownRef2 = useRef(null);

  useEffect(() => {
    // Dışarıya tıklandığında dropdown'u kapat
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setDropdownOpen(false);
      }
    };

    // Etkinlik dinleyicisini ekleyin
    document.addEventListener("mousedown", handleClickOutside);

    // Temizlik: bileşen kaldırıldığında etkinlik dinleyicisini kaldırın
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  useEffect(() => {
    // Dışarıya tıklandığında dropdown'u kapat
    const handleClickOutside = (event) => {
      if (dropdownRef2.current && !dropdownRef2.current.contains(event.target)) {
        setDropdownOpen2(false);
      }
    };

    // Etkinlik dinleyicisini ekleyin
    document.addEventListener("mousedown", handleClickOutside);

    // Temizlik: bileşen kaldırıldığında etkinlik dinleyicisini kaldırın
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const toggleDropdown = () => {
    setDropdownOpen(!dropdownOpen);
  };
  const toggleDropdown2 = () => {
    setDropdownOpen2(!dropdownOpen2);
  };

  const closeNavMenu = () => { 
    setNavList(false);
  };

  return (
    <>
      <header>
        <div className="container flex">
          <div className="logo">
            <img src="/./images/banner8.png" alt="" />
          </div>
          <div className="nav">
            <ul className={navList ? "small" : "flex"}>
              {nav.map((list, index) => (
                <li key={index}>
                  <Link to={list.path} onClick={closeNavMenu}>
                    {list.text}
                  </Link>
                </li>
              ))}
                <li style={{display:'flex', justifyContent:'left'}}>
                <Link to="/avadanliqlar">
                  Avadanlıqlar
                </Link>
                <div
                ref={dropdownRef2}
                className="dropdown"
                style={{ float: "right"}}
              >
                <div
                  onClick={toggleDropdown2}
                  style={{ cursor: "pointer", position: "relative", top: "4px", left: "3px" }}
                >
                  {dropdownOpen2 ? <FiChevronUp /> : <FiChevronDown />}
                </div>
                {dropdownOpen2 && (
                  <div className="dropdown-content">
                    <a href="/barkod-oxuyucu" onClick={closeNavMenu}>Barkod oxuyucular</a>
                    <a href="/barkod-printer" onClick={closeNavMenu}>Barkod printerlər</a>
                    <a href="/cek-printer" onClick={closeNavMenu}>Çek printerlər</a>
                    <a href="/barkod-terezi" onClick={closeNavMenu}>Barkod tərəzilər</a>
                    <a href="/touchscreen" onClick={closeNavMenu}>Touchscreen</a> 
                    <a href="/pul-qutulari" onClick={closeNavMenu}>Pul Qutuları</a>
                    <a href="/fiyatgor" onClick={closeNavMenu}>Fiyatgörlər</a>
                    <a href="/komputerler" onClick={closeNavMenu}>Komputerlər</a>
                    <a href="/kagizlar" onClick={closeNavMenu}>Kağızlar</a>
                    <a href="/cardreader" onClick={closeNavMenu}>Kart oxuyucuları</a>
                    
                  </div>
                )}
              </div>
              </li>
              <li style={{display:'flex', justifyContent:'left'}}>
                <Link to="/sektorlar">
                  Sektorlar   
                </Link>
                <div
                ref={dropdownRef}
                className="dropdown"
                style={{ float: "right"}}
              >
                <div
                  onClick={toggleDropdown}
                  style={{ cursor: "pointer", position: "relative", top: "4px", left: "3px" }}
                >
                  {dropdownOpen ? <FiChevronUp /> : <FiChevronDown />}
                </div>
                {dropdownOpen && (
                  <div className="dropdown-content">
                    <a href="/market" onClick={closeNavMenu}>Market</a>
                    <a href="/restoran" onClick={closeNavMenu}>Restoran</a>
                    <a href="/kafe" onClick={closeNavMenu}>Kafe</a>
                    <a href="/kofeshop" onClick={closeNavMenu}>KofeShop</a>
                    <a href="/xirdavat" onClick={closeNavMenu}>1001 Xırdavat</a>
                    <a href="/telefon" onClick={closeNavMenu}>Telefon mağazaları</a>
                    <a href="/geyim" onClick={closeNavMenu}>Geyim mağazaları</a>
                    <a href="/aptek" onClick={closeNavMenu}>Aptek</a>
                    <a href="/kiosk" onClick={closeNavMenu}>Kiosk</a>
                    <a href="/oyuncaq" onClick={closeNavMenu}>Oyuncaq mağazaları</a>
                    <a href="/avto" onClick={closeNavMenu}>Avto Ehtiyyat Hissələri</a>
                    <a href="/zoo" onClick={closeNavMenu}>Zoo mağazaları</a>
                    <a href="/parfumeriya" onClick={closeNavMenu}>Parfumeriya</a>
                    
                  </div>
                )}
              </div>
              </li>
            </ul>
          </div>
          <div className="button btncall">
            <a href="tel:+994509900049">
              {" "}
              <button className="btn1">
              <IoCall size={20} />
              </button>
            </a>
          </div>
          <div className="button btnwp">
            <a href="https://api.whatsapp.com/send?phone=994509900049&text=">
              {" "}
              <button style={{backgroundColor: '#25D366'}} className="btn11">
                {/* <LuMessageSquare size={20} /> */}
                <FaWhatsapp size={20}  />
              </button>
            </a>
          </div>
        

          <div className="toggle">
            <button onClick={() => setNavList(!navList)}>
              {navList ? <i className="fa fa-times"></i> : <i className="fa fa-bars"></i>}
            </button>
          </div>
        </div>
      </header>
    </>
  );
};

export default Header;