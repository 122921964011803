import React from "react"
import { price } from "../../data/Data"

const PriceCard = () => {
  return (
    <>
      <div className='corporation content flex mtop'>
        {price.map((item, index) => (
          <div className='box shadow' key={index}>
            {/* <div className='topbtn'>
              <button className='btn3'>{item.best}</button>
            </div> */}
            <img src={item.image}></img>
          </div>
        ))}
      </div>
    </>
  )
}

export default PriceCard
