import React, { useEffect, useState } from 'react';
import Back from "../common/Back";
import Heading from "../common/Heading";
import img from "../images/about.jpg";
import "./SoftwareProgram.css";
import axios from 'axios';
import { useNavigate } from 'react-router-dom';

const SoftwareProgram = () => {
  const navigate = useNavigate();
  const [list, setItems] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    getProducts();
  }, []);

  const getProducts = async () => {
    try {
      const response = await axios.get(
        "https://www.posstore.az/posstore/softwareProgram.php/",
        { withCredentials: false }
      );
      setItems(response.data);
    } catch (error) {
      console.error("Error fetching products:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const toDetails = (id) => {
    navigate(`/software-program/${id}`);
  };

  return (
    <section className='blog-out mb'>
      <Back name='Proqram təminatı' title='Proqram təminatı' cover={img} />
      <div className='container recent'>
        {isLoading ? (
          <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '50vh' }} className='container'>
            <img style={{ width: '50px', height: '50px', margin: '0 auto' }} src='/loading.gif' alt="Loading" />
          </div>
        ) : (
          <div className='content grid3 mtop'>
            {list.length > 0 ? (
              list.map((val) => {
                const { id, image, title } = val;
                return (
                  <div onClick={() => toDetails(id)} className='box shadow' key={id}>
                    <div className='img'>
                      <img src={`data:image/jpeg;base64,${image}`} alt='Product' />
                    </div>
                    <div className='text'>
                      <h4>{title}</h4>
                    </div>
                  </div>
                );
              })
            ) : (
              <div className='no-results'>Proqram təminatı tapılmadı</div>
            )}
          </div>
        )}
      </div>
    </section>
  );
};

export default SoftwareProgram;


