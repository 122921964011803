import React from "react"
import Back from "../../common/Back"
import Heading from "../../common/Heading"
import img from "../../images/about.jpg"
import "./xirdavat.css"

const Xirdavat = () => {
  return (
    <>
      <section className='market'>
        <Back name='1001 Xırdavat' title='Sektorlar - 1001 Xırdavat' cover={img} />
        <div className='container flex mtop'>
          <div className='left row'>
            <Heading title='1001 Xırdavat' subtitle='Haqqımızda daha çox oxuyun' />

            <p>"POS GROUP" şirkəti olaraq sizi salamlayırıq.

2020-ci il tarixindən rəsmi "POS GROUP"MMC şirkəti adından fəaliyyət göstərən şirkət,daima keyfiyyətli avadanlıq,yüksək xidmət və sərfəli qiymətlərlə müştərilərin etimadını qazanmışdır.

Şirkətimiz istənilən ticarət obyektləri üçün Barkod Pos sistemi avadanlıqlarının satışını və Proqram təminatının yazılımını icra edir.</p>
            <a href="/about"><button className='btn2'>Haqqımızda daha çox</button></a>
          </div>
          <div className='right row'>
            <img src='./about.webp' alt='' />
          </div>
        </div>
      </section>
    </>
  )
}

export default Xirdavat
