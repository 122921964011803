import React, { useEffect, useState } from "react";
import Back from "../common/Back";
import Heading from "../common/Heading";
import img from "../images/about.jpg";
import "./SoftwareProgramSingle.css";
import { useParams } from "react-router-dom";
import axios from "axios";

const SoftwareProgramSingle = () => {
  const { id } = useParams();
  const [list, setItems] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    getProducts();
  }, [id]);

  const getProducts = async () => {
    try {
      const response = await axios.get(
        `https://www.posstore.az/posstore/softwareProgram.php/${id}`
      );
      setItems([response.data]);
    } catch (error) {
      console.error("Error fetching products:", error);
    } finally {
      setIsLoading(false);
    }
  };

  if (isLoading) {
    return   <div style={{display:'flex', justifyContent: 'center', alignItems:'center', height:'50vh'}} className='container'>
    <img style={{width:'50px', height:'50px', margin: '0 auto'}} src='/./loading.gif'></img>
  </div>; // Simple loading text
  }

  return (
    <>
      <section className="market">
        <Back name="Proqram təminatı" title="Proqram təminatı" cover={img} />
        
        <div className="container flex mtop">
          <div className="left row">
            {list.map((val) => {
              const { image, video, text, title, id } = val;
              return (
                <div key={id}>
                  <p>{title}</p>
                  <div className="right now">
                    <img
                      src={`data:image/jpeg;base64,${image}`}
                      alt="Proqram təminatı"
                    />
                  </div>
                  <p>{text}</p>
                  {video && (
                    <div className="video">
                      <video width="320" height="240" controls>
                        <source
                          src={`data:video/mp4;base64,${video}`}
                          type="video/mp4"
                        />
                        Your browser does not support the video tag.
                      </video>
                    </div>
                  )}
                  
                </div>
              );
            })}
          </div>
        </div>
      </section>
    </>
  );
};

export default SoftwareProgramSingle;




